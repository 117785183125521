import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'

import DefaultImage from '../img/news/share/image_001.png';

class BlogPickupRoll extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark

    return (
      <>
        <article className="c-news-tile">
          {posts &&
            posts
            .map(({ node: post }) => (
              <Link className="c-news-tile__item" to={post.fields.slug} key={post.id}>
                <div className="c-news-tile__row">
                  <div className="c-news-tile__row__image">
                    {post.frontmatter.featuredimage ? (
                      <PreviewCompatibleImage
                        imageInfo={{
                          image: post.frontmatter.featuredimage,
                          alt: `featured image thumbnail for post ${post.frontmatter.title}`,
                        }}
                      />
                    ) : <img src={DefaultImage} alt="天神皮ふ科" />}
                  </div>
                  <div className="c-news-tile__row__content">
                    <div className="c-news-tile__row__content__date">
                      <span>{post.frontmatter.date}</span>
                    </div>
                    <div className="c-news-tile__row__content__text">{post.frontmatter.title}</div>
                  </div>
                </div>
              </Link>
            ))
          }

          <div className="c-news-tile__button">
            <Link className="c-border-button" to={"/blog"}>
              <p className="c-border-button__text">もっと見る</p>
            </Link>
          </div>
        </article>
      </>



      // <div className="columns is-multiline">
      //   {posts &&
      //     posts.map(({ node: post }) => (
      //       <div className="is-parent column is-6" key={post.id}>
      //         <article
      //           className={`blog-list-item tile is-child box notification ${
      //             post.frontmatter.featuredpost ? 'is-featured' : ''
      //           }`}
      //         >
      //           <header>
      //             {post.frontmatter.featuredimage ? (
      //               <div className="featured-thumbnail">
      //                 <PreviewCompatibleImage
      //                   imageInfo={{
      //                     image: post.frontmatter.featuredimage,
      //                     alt: `featured image thumbnail for post ${post.frontmatter.title}`,
      //                   }}
      //                 />
      //               </div>
      //             ) : null}
      //             <p className="post-meta">
      //               <Link
      //                 className="title has-text-primary is-size-4"
      //                 to={post.fields.slug}
      //               >
      //                 {post.frontmatter.title}
      //               </Link>
      //               <span> &bull; </span>
      //               <span className="subtitle is-size-5 is-block">
      //                 {post.frontmatter.date}
      //               </span>
      //             </p>
      //           </header>
      //           <p>
      //             {post.excerpt}
      //             <br />
      //             <br />
      //             <Link className="button" to={post.fields.slug}>
      //               続きを読む
      //             </Link>
      //           </p>
      //         </article>
      //       </div>
      //     ))}
      // </div>
    )
  }
}

BlogPickupRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export default () => (
  <StaticQuery
    query={graphql`
      query BlogPickupRollQuery {
        allMarkdownRemark(
          limit: 3
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              excerpt(pruneLength: 400)
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "YYYY.MM.DD")
                featuredpost
                featuredimage {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogPickupRoll data={data} count={count} />}
  />
)
