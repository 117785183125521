import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import BlogPickupRoll from "../components/BlogPickupRoll";

// images
import AboutImage from "../img/top/about.png";

export const SharePickupNewsPageTemplate = ({ content, contentComponent }) => {
  return (
    <>
      <section className="l-section-lv2 l-main-content-hero -hero-lv2">
        <div className="l-main-content-hero__inner">
          <div className="c-h2-title">
            <div className="c-h2-title__inner">
              <h2 className="c-h2-title__text">-BLOG-</h2>
              <p className="c-h2-title__description">当院の新着情報</p>
            </div>
          </div>

          <BlogPickupRoll />
        </div>
      </section>
    </>
  );
};

SharePickupNewsPageTemplate.propTypes = {
  title: PropTypes.string
};

const SharePickupNewsPage = ({ data }) => {
  // const { markdownRemark: post } = data;

  return <SharePickupNewsPageTemplate />;
};

SharePickupNewsPage.propTypes = {
  data: PropTypes.object
};

export default SharePickupNewsPage;

export const sharePickupNewsPageQuery = graphql`
  query SharePickupNewsPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`;
